
body {
  font: 1em $body-font-stack;
  background-color: $theme-color-background-light;
  color: $theme-color-text;
}

p {
  line-height: 130%;
}

li {
  padding-bottom: 1%;
}

dt {
  font-style: italic;
  font-weight: normal;
  margin-left: 2%;
}

dd {
  margin-left: 3%;
}

h1 {
  font: small-caps bold italic 190% $heading-font-stack;
  text-indent: 0%;
  padding-top: $heading-padding-top;
  padding-bottom: $heading-padding-bottom;
}

h2 {
  font: 160% $heading-font-stack;
  text-indent: 0%;
  padding-top: $heading-padding-top;
  padding-bottom: $heading-padding-bottom;
}

h3 {
  font: italic 100% $heading-font-stack;
  //text-indent: 2%;
  padding-top: $heading-padding-top;
  padding-bottom: $heading-padding-bottom;
}

h4 {
  font: small-caps 160% $heading-font-stack;
  text-indent: 5%;
  padding-top: $heading-padding-top;
  padding-bottom: $heading-padding-bottom;
}

h5 {
  font: 160% $heading-font-stack;
  text-indent: 5%;
  padding-top: $heading-padding-top;
  padding-bottom: $heading-padding-bottom;
}

h6 {
  font: italic 155% $heading-font-stack;
  padding-top: $heading-padding-top;
  padding-bottom: $heading-padding-bottom;
}

