$body-font-stack:  Verdana, sans-serif;
$heading-font-stack:  Arial, sans-serif;

$heading-padding-bottom: 0.3%;
$heading-padding-top: 1%;

$tagline-padding-top: 0.5%;
$tagline-padding-bottom: 0.5%;

$theme-color-text: black;
$theme-color-text-highlight: maroon;
$theme-color-link-highlight: maroon;
$theme-color-background-light: #FFFFEE;
$theme-color-background-dark: lightgray;
$theme-color-background-dim: dimgray;
$theme-color-border: dimgray;

hr {
  border-color: $theme-color-border;
  margin-top: 0.9%;
  margin-bottom: 0.9%;
}


a:link, a:visited {
  color: $theme-color-link-highlight;
  text-decoration: none;
}

a:hover, a:active {
  color: $theme-color-link-highlight;
  text-decoration: underline;
}

