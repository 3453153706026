.work-history-item {
  padding-top: 2%;
  padding-bottom: 2%;
}

.work-history-item h2 {
  margin: 0;
  padding: 0;
}

.work-history-details {
  margin-left: inherit;
}
.work-history-employer {
  font-style: inherit;
}

.work-history-location {
  font-style: inherit;
}

.work-history-position-description {
  color: inherit;
}

.work-history-outcomes {
  color: inherit;
}

.work-history-outcome {
  color: inherit;
}

.work-history-tech {
  color: inherit;
}

.work-history-tech-items {
  color: inherit;
}

.work-history-meta {
  color: $theme-color-background-dim;    
}

.work-history-date {
  display: inline-block;
  font-style: italic;
}

.note {
  border-style: ridge;
  border-width: thin;
  border-radius: 1em;
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 15%;
  margin-bottom: 2%;
  padding: 1%;
  font-variant: small-caps;
  color: $theme-color-text-highlight;
  background-color: $theme-color-background-dark;
}

.note-body {
  font: initial;
  //display: inline;
  color: $theme-color-text;
  padding-top: 2%;
  padding-bottom: 0%;
}
