.post-list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.post-list li {
  padding-bottom: 3%;
}

.post-list .post-title {
  font: bold 120% $heading-font-stack;
}

.post-list .post-excerpt {
  padding-top: 1%;
}

.post-list .post-meta {
  color: $theme-color-background-dim;
  font-size: 80%;
}

