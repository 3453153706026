.policy-block {
  text-align: center;
}

.policy-text {
  text-align: justify;
}

.site-title {
  font: bold 200% $heading-font-stack;
  text-align: center;
}

.site-tagline {
  font: italic 75% $heading-font-stack;
  padding-top: $tagline-padding-top;
  padding-bottom: $tagline-padding-bottom;
  text-align: center;

}

.header-nav-item {
  padding-left: 2%;
  padding-right: 2%;
}

.section-title {
  font: italic 100% $heading-font-stack;
  //text-indent: 2%;
  padding-top: $heading-padding-top;
  padding-bottom: $heading-padding-bottom;
}

.footer-text {
  font: 80% $heading-font-stack;
  color: $theme-color-background-dim;
}

.social-icon--title {
  font: 100%;
}
.social-icon--link {
  display: inline-block;
  width: 1.5%;
  min-height: 20px;
  min-width: 20px;
  height: 1.5%;
  background-size: 1.5% 1.5%;
  box-sizing: border-box;
  padding: 0.25%;
  fill: $theme-color-border;
}

.read-more {
  font-size: 80%;
  font-style: italic;
  //font-variant: small-caps;
}

.publication-history {
  font-size: 80%;
}