$border-radius-vert: 0%;
$border-radius-horiz: 0%;

.portfolio-card {
    border-top-left-radius: $border-radius-horiz $border-radius-vert;
    border-top-right-radius: $border-radius-horiz $border-radius-vert;
    border-bottom-left-radius: $border-radius-horiz $border-radius-vert;
    border-bottom-right-radius: $border-radius-horiz $border-radius-vert;
    border-width: thin;
    border-style: none;
    border-color: $theme-color-border;
    background-color: $theme-color-background-dark;
    color: $theme-color-text;
}

.portfolio-card-spaced {
    padding-top: 5%;
    padding-bottom: 5%;
}
.portfolio-tagline-number {
    font-size: 300%;
    font-weight: bold;
    color: $theme-color-text-highlight;
}
  
.portfolio-tagline-text {
    font-size: 120%;
    font-variant: small-caps;
    font-weight: bold;
    color: $theme-color-text-highlight;
}
  
.portfolio-project-role {
    font-weight: bold;
}
  
.portfolio-project-company {
    font-style: normal;
}

.portfolio-data-header {
    font-style: italic;
    font-size: 90%;
}

.portfolio-data {
    font-size: 70%;
}

.portfolio-collection-list {
    margin: 0;
    padding-top: 0;
    padding-bottom: 3%;
    padding-left: 0;
    padding-right: 0;
}

.portfolio-collection-item {
    display: inline-block;
    color: $theme-color-text;
    border-style: solid;
    border-color: $theme-color-border;
    border-width: thin;
    border-top-left-radius: 2em 2em;
    border-top-right-radius: 1em 1em;
    border-bottom-left-radius: 1em 1em;
    border-bottom-right-radius: 2em 2em;
    margin: 1%;
    padding-left: 0.6em;
    padding-right: 0.7em;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
}