.k-text-superscript {
  // Used for a font icon like the external link indicator -- makes it smaller and superscripts it
  font-size: 50%;
  vertical-align: text-top;
}

.k-text-icon {
  // Default text icon formatting
  color: $theme-color-border;
}

.k-icon-padded {
  padding-left: 2%;
  padding-right: 2%;
}

.k-gravatar {
  padding: 2%;
}

.k-small {
  font-size: 60%;
}

.k-emphatic-quote {
  font-variant: small-caps;
}

.k-vertical-align-middle {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.k-indented {
  display: inline-block;
  margin-left: 3%;
}

.k-narrow-body {
  width: 50%;
}
